<template>
    <div>
        <v-container
            id="groups"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="auto" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Profile Management</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedMinistryPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="ministryPages"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 16rem; color: black !important"
                            clearable
                            @click:clear="table.pagination.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>

                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            depressed
                            rounded
                            dark
                            color="#2C423D"
                            @click="openGroupFormDialog('add')"
                            >Add Group</v-btn
                        >
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            placeholder="Filter by Group Type"
                            readonly
                            @click="isSearchDialogOpen = true"
                            clearable
                            @click:clear="table.options.groupType = null"
                            v-model="table.options.groupType"
                        ></v-text-field>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Filter by Group Size"
                            :items="perPageItems"
                            clearable
                            @click:clear="table.options.groupSize = null"
                            v-model="table.options.groupSize"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by Creation Date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="max-width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>

            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col>
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.created_at="{ item }">
                                <span>{{ formatDate(item.created_at) }}</span>
                            </template>
                            <template v-slot:item.created_by="{ item }">
                                <span>{{
                                    item.created_by
                                        ? `${item.created_by.first_name} ${item.created_by.last_name}`
                                        : "--"
                                }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-hover v-slot="{ hover }">
                                    <v-btn
                                        block
                                        depressed
                                        dark
                                        :color="hover ? '#389877' : '#78756A20'"
                                        rounded
                                        @click="
                                            openMinistryInformationDialog(item)
                                        "
                                    >
                                        <v-icon
                                            :color="hover ? '#fff' : '#78756A'"
                                            >mdi-fullscreen</v-icon
                                        >
                                    </v-btn>
                                </v-hover>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <group-form-dialog
            :show.sync="groupForm.show"
            :action="groupForm.action"
            :data="groupForm.data"
            @onSuccess="getGroups"
            @close="groupForm = { ...groupForm, show: false, data: null }"
        ></group-form-dialog>

        <ministry-information-dialog
            :show.sync="groupInformationDialog.show"
            selectedProfile="Groups"
            selectedMinistryPage="Groups"
            :loadedProfile="groupInformationDialog.data"
            @close="groupInformationDialog.show = false"
            @showUpdateForm="openGroupFormDialog('edit')"
            v-if="groupInformationDialog.data"
        ></ministry-information-dialog>

        <custom-search-dialog
            :is-open.sync="isSearchDialogOpen"
            title="Search Group Type"
            :action="getGroupCategoriesAction"
            item-text="name"
            @onSelect="onSelectGroupType"
        ></custom-search-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.dateFrom"
            :end-date.sync="table.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import Pagination from "../../../Core/Pagination.vue";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { GET_GROUP_CATEGORIES, GET_GROUPS } from "@/store/modules/group";
import GroupFormDialog from "@/components/MainView/Ministry/Groups/components/GroupFormDialog";
import MinistryInformationDialog from "@/components/MainView/Ministry/modals/view-modal";
import dateUtility from "@/mixins/date";

export default {
    name: "ministry-groups",

    components: {
        MinistryInformationDialog,
        GroupFormDialog,
        CustomSearchDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    dateFrom: null,
                    dateTo: null,
                    groupType: null,
                    groupSize: null,
                    sortBy: "desc",
                },
                headers: [
                    {
                        text: "Group Type",
                        value: "group_category.name",
                        sortable: false,
                    },
                    { text: "Group Name", value: "name", sortable: false },
                    {
                        text: "Description",
                        value: "description",
                        sortable: false,
                    },
                    {
                        text: "No. of Members",
                        value: "number_of_members",
                        sortable: false,
                    },
                    {
                        text: "Created by",
                        value: "created_by",
                        sortable: false,
                    },
                    {
                        text: "Created On",
                        value: "created_at",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            isSearchDialogOpen: false,
            getGroupCategoriesAction: GET_GROUP_CATEGORIES,
            groupForm: { show: false, action: "", data: null },
            groupInformationDialog: { show: false, data: null },
            selectedMinistryPage: "/ministry/groups",
            ministryPages: [
                {
                    text: "Users",
                    value: "/ministry/users",
                },
                {
                    text: "Groups",
                    value: "/ministry/groups",
                },
                {
                    text: "Requests",
                    value: "/ministry/requests",
                },
                {
                    text: "Announcements",
                    value: "/ministry/announcements",
                },
            ],
            focus: "",
            userForm: { show: false, action: "", data: {} },
            viewData: {},
            show: false,
            showViewModal: false,
            loadedProfile: {},
            showAddUser: false,
            updateData: {},
            allUsers: [],
            showAddGroup: false,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        getButtonName() {
            let index = this.ministryPages.findIndex(
                (item) => item.value === this.selectedMinistryPage
            );
            return this.ministryPages[index].text;
        },
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getGroups();
            },
            deep: true,
        },
    },

    methods: {
        async getGroups() {
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_GROUPS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getGroups();
        },

        openGroupFormDialog(action) {
            switch (action) {
                case "add":
                    this.groupForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.groupForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.groupInformationDialog.data
                        ),
                    };
                    this.groupInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.groupForm = { show: true, action, data: null };
                    break;
            }
        },

        onOpenUpdateGroupForm({ data }) {
            this.showViewModal = false;
            this.groupInformationDialog.data = Object.assign({}, data);
            this.openGroupFormDialog("edit");
        },

        openMinistryInformationDialog(data) {
            this.groupInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        onSelectGroupType(groupType) {
            this.table.options.groupType = groupType.name;
        },

        formatDate(timestamp) {
            return dateUtility.toSimpleDate(timestamp);
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        async initiate() {
            this.selectedMinistryPage = this.$route.path;
            await this.getGroups();
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
