<template>
    <base-dialog :width="dialogWidth" :is-open.sync="dialog">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold">View </span>
            <v-btn
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#389877"
                @click="checkProfile(profile)"
                >Update</v-btn
            >
            <v-btn
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#983838"
                @click="isDeletingUser.showModal = true"
                >Delete</v-btn
            >
        </v-card-title>
        <v-container fluid>
            <v-row v-if="profile === 'Users'">
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Personal</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Prefix</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.prefix)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >First Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.first_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Middle Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.middle_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Last Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.last_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Suffix</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.suffix)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Nickname</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.nickname)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Birthdate</label
                        ><span style="width: 50%">{{
                            $checkContent(
                                new Date(
                                    itemData.birthdate
                                ).toLocaleDateString()
                            )
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Gender</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.gender)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Marital Status</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.marital_status)
                        }}</span>
                    </div>
                </v-col>
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Contact</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Address</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.address)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Contact Number</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.contact_number)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Email Address</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.email)
                        }}</span>
                    </div>
                    <span
                        class="ml-4 mt-5 text-md font-weight-bold section-header"
                        >Work</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Occupation</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.occupation)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Company</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.company)
                        }}</span>
                    </div>
                </v-col>
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Membership</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Status</label
                        ><span style="width: 50%">{{
                            itemData.user_information.membership.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Designation</label
                        ><span style="width: 50%">{{
                            getInfo("designation")
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >User Type</label
                        ><span style="width: 50%">{{ getInfo("role") }}</span>
                    </div>
                    <span
                        class="ml-4 mt-5 text-md font-weight-bold section-header"
                        >Family</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Father</label
                        ><span style="width: 50%">{{ getInfo("father") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Mother</label
                        ><span style="width: 50%">{{ getInfo("mother") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Sibling(s)</label
                        ><span style="width: 50%">{{
                            getInfo("siblings")
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Spouse</label
                        ><span style="width: 50%">{{ getInfo("spouse") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Children</label
                        ><span style="width: 50%">{{
                            getInfo("children")
                        }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="profile === 'Groups'">
                <v-col
                    md="12"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Group Type</label
                        ><span style="width: 70%">{{
                            itemData.group_category.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Group Name</label
                        ><span style="width: 70%">{{ itemData.name }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Description</label
                        ><span style="width: 70%">{{
                            itemData.group_category.description
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Members
                            <span>
                                <v-btn
                                    x-small
                                    depressed
                                    dark
                                    fab
                                    color="#389877"
                                    class="add-btn ml-2"
                                    @click="isSearchMemberDialogOpen = true"
                                    ><v-icon small
                                        >mdi-plus-thick</v-icon
                                    ></v-btn
                                >
                            </span></label
                        >

                        <div style="width: 70%" class="members-container">
                            <div
                                style="width: 100%"
                                v-for="member in groupMembers"
                                :key="member.id"
                                class="inner-member pr-7"
                            >
                                <span>
                                    {{
                                        `${member.user.first_name} ${member.user.last_name}`
                                    }}</span
                                >
                                <v-btn
                                    depressed
                                    rounded
                                    dark
                                    small
                                    color="#983838"
                                    class="px-6"
                                    @click="onDeleteMember(member)"
                                    >Delete</v-btn
                                >
                            </div>
                            <span v-if="!getInfo('members')">None</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="profile === 'Requests'">
                <v-col
                    md="12"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Request Type</label
                        ><span style="width: 60%">{{
                            itemData.request_type.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Requested By</label
                        ><span style="width: 60%">{{
                            `${itemData.requested_by.first_name} ${itemData.requested_by.last_name}`
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Submitted On</label
                        ><span style="width: 60%">{{
                            new Date(itemData.created_at).toLocaleString()
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Notes</label
                        ><span style="width: 60%">{{ itemData.notes }}</span>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <base-dialog :width="500" :is-open.sync="isDeletingUser.showModal">
            <v-card-title style="background-color: #f1eeee">
                <span style="color: #78756a" class="font-weight-bold"
                    >Delete User</span
                >
                <v-container fluid>
                    <div
                        class="d-flex flex-column justify-center align-center h-100 w-100"
                    >
                        <span class="font-size-16 mt-2"
                            >Are you sure you want to delete this user?</span
                        >
                    </div>
                    <v-card-actions class="">
                        <v-container fluid>
                            <v-row justify="end" class="action-row">
                                <v-btn
                                    class="border-radius-25 px-5 mr-3"
                                    depressed
                                    rounded
                                    dark
                                    color="secondary"
                                    :loading="isDeletingUser.loading"
                                    @click="isDeletingUser.showModal = false"
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    class="border-radius-25 px-5"
                                    depressed
                                    rounded
                                    dark
                                    :loading="isDeletingUser.loading"
                                    color="#983838"
                                    @click="deleteUser(loadedProfile.id)"
                                    >Delete</v-btn
                                >
                            </v-row>
                        </v-container></v-card-actions
                    >
                </v-container>
            </v-card-title>
        </base-dialog>
        <MemberModal
            v-if="showMemberModal"
            :show="showMemberModal"
            @close="showMemberModal = false"
            :group_id="itemData.id"
            @getData="updateGroup"
            :groupMethod="groupMethod"
            :currentMember="currentMember"
            @deleteUser="deleteUser"
            :groupMembers="groupMembers"
        />

        <custom-search-dialog
            :is-open.sync="isSearchMemberDialogOpen"
            title="Search Users"
            :action="getMembersAction"
            @onSelect="onSelectMember"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>
    </base-dialog>
</template>

<script>
import MemberModal from "./member-modal.vue";
import BaseDialog from "@/components/Base/BaseDialog";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { GET_USERS, DELETE_USER } from "@/store/modules/user";
import { ADD_GROUP_MEMBER, DELETE_GROUP_MEMBER } from "@/store/modules/group";

export default {
    name: "ministry-information-dialog",

    props: {
        show: { type: Boolean, default: false, required: true },
        selectedProfile: String,
        loadedProfile: Object,
    },

    data() {
        return {
            dialog: this.show,
            showMemberModal: false,
            groupMethod: "",
            currentMember: "",
            isSearchMemberDialogOpen: false,
            getMembersAction: GET_USERS,
            loadedProfileLocal: Object.assign({}, this.loadedProfile),
            isDeletingUser: {
                loading: false,
                showModal: false,
            },
        };
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(to) {
            if (!to) {
                this.$emit("close");
            }
        },
    },

    methods: {
        showModal(method, member) {
            this.showMemberModal = true;
            this.groupMethod = method;
            this.currentMember = member;
        },
        updateGroup() {
            this.$emit("getGroup", this.itemData.id);
        },
        checkProfile(profile) {
            switch (profile) {
                case "Groups":
                    // console.log("open group form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
                    break;
                case "Requests":
                    // console.log("open request form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
                    break;
                default:
                    // console.log("open user form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
            }
            // console.log("checking");
        },
        deleteUser(id) {
            this.isDeletingUser.loading = true;
            this.$store
                .dispatch(DELETE_USER, id)
                .then(() => {
                    this.$emit("onSuccess");
                    this.$emit("updateTable");
                    this.isDeletingUser.loading = false;
                    this.isDeletingUser.showModal = false;
                    this.dialog = false;
                    this.$notify({
                        group: "main-notifications",
                        type: "success",
                        title: "Delete User",
                        text: "User successfully deleted",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // deleteUser(group, member) {
        //     this.$store
        //         .dispatch("profile_groups/deleteUserWithBody", {
        //             id: group,
        //             data: {
        //                 user_ids: [member],
        //             },
        //         })
        //         .then((response) => {
        //             this.$notify({
        //                 group: "main-notifications",
        //                 type: "success",
        //                 title: "User Deleted",
        //                 text: response.message,
        //             });
        //             this.updateGroup();
        //         })
        //         .catch((error) => {
        //             this.$notify({
        //                 group: "main-notifications",
        //                 type: "error",
        //                 title: error.message,
        //                 text: error.response.data.message,
        //             });
        //         });
        // },
        closeForm() {
            this.$emit("close");
        },
        getFamily(type) {
            const familyItem = [];
            this.itemData.user_relationships.map((item) => {
                if (item.relationship.name === type) {
                    familyItem.push(
                        `${item?.relate_user.first_name} ${item?.relate_user.last_name}`
                    );
                }
            });
            if (familyItem.length === 0) {
                return "--";
            }
            return familyItem.join(", ");
        },
        getInfo(type) {
            let outArr = [];
            if (type === "designation") {
                this.itemData.user_designations.forEach((item) => {
                    outArr.push(item.designation.name);
                });
                return outArr.join(", ");
            }
            if (type === "role") {
                this.itemData.user_roles.forEach((item) => {
                    outArr.push(item.role.name);
                });
                return outArr.join(", ");
            }
            if (type === "father") {
                return this.getFamily("Father");
            }
            if (type === "mother") {
                return this.getFamily("Mother");
            }
            if (type === "spouse") {
                return this.getFamily("Spouse");
            }
            if (type === "siblings") {
                return this.getFamily("Sibling");
            }
            if (type === "children") {
                return this.getFamily("Child");
            }
            if (type === "members") {
                const members = [];
                this.itemData.user_groups.forEach((item) => {
                    members.push(
                        `${item.user.first_name} ${item.user.last_name}`
                    );
                });
                if (members.length === 0) {
                    return false;
                }
                return members;
            }
        },

        async onSelectMember(member) {
            const existingMembers = this.itemData.user_groups.map(
                (user) => user.user.id
            );
            if (existingMembers.includes(member.id)) {
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    title: "Existing Group Member",
                    text: `${member.first_name} ${member.last_name} was already in the list.`,
                });
                return;
            }
            const payload = {
                groupID: this.itemData.id,
                user_ids: [member.id],
            };
            const { success } = await this.$store.dispatch(
                ADD_GROUP_MEMBER,
                payload
            );
            if (success) {
                this.loadedProfileLocal = Object.assign(
                    {},
                    {
                        ...this.loadedProfileLocal,
                        user_groups: [
                            ...this.loadedProfileLocal.user_groups,
                            { user: Object.assign({}, member) },
                        ],
                    }
                );
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "New Group Member Added",
                    text: `${member.first_name} ${member.last_name} Added.`,
                });
                return;
            }
            this.$notify({
                group: "main-notifications",
                type: "error",
                title: "Group Member",
                text: "Something went wrong.",
            });
        },

        async onDeleteMember(member) {
            const user = member.user;
            const payload = {
                groupID: this.itemData.id,
                user_ids: [user.id],
            };
            const { success } = await this.$store.dispatch(
                DELETE_GROUP_MEMBER,
                payload
            );
            if (success) {
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Group Member",
                    text: `${user.first_name} ${user.last_name} Deleted.`,
                });
                this.loadedProfileLocal = Object.assign(
                    {},
                    {
                        ...this.loadedProfileLocal,
                        user_groups: this.loadedProfileLocal.user_groups.filter(
                            (_member) => _member.user.id !== user.id
                        ),
                    }
                );
                return;
            }
            this.$notify({
                group: "main-notifications",
                type: "error",
                title: "Group Member",
                text: "Something went wrong.",
            });
        },
    },

    computed: {
        users() {
            return this.allUsers;
        },
        profile() {
            return this.selectedProfile;
        },
        dialogWidth() {
            if (this.selectedProfile === "Users") {
                return 1350;
            } else if (this.selectedProfile === "Groups") {
                return 650;
            } else {
                return 600;
            }
        },
        itemData() {
            return this.loadedProfileLocal;
        },
        groupMembers() {
            return this.itemData.user_groups;
        },
    },

    mounted() {},

    components: { CustomSearchDialog, BaseDialog, MemberModal },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.section-header {
    color: #389877;
}

.item-column {
    gap: 20px;
}

.members-container {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 10px 0;
}

.inner-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-btn {
    width: 25px !important;
    height: 25px !important;
}

.action-row {
    margin-top: 5px;
    margin-bottom: -50px;
}
</style>
