<template>
    <base-dialog width="600" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <base-input-label required>Leader</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            readonly
                            :value="form.groupLeader.name"
                            @click="isSearchGroupLeaderDialogOpen = true"
                            clearable
                            @click:clear="clearCustomSearchInput('groupLeader')"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Group Name</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.name"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Group Type</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            readonly
                            :value="form.groupCategory.name"
                            @click="isSearchGroupTypeDialogOpen = true"
                            clearable
                            @click:clear="
                                clearCustomSearchInput('groupCategory')
                            "
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required
                            >Description</base-input-label
                        >
                        <v-textarea
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.description"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>

        <custom-search-dialog
            :is-open.sync="isSearchGroupLeaderDialogOpen"
            title="Search Group Leader"
            :action="getGGLeadersAction"
            @onSelect="onSelectGroupLeader"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>

        <custom-search-dialog
            :is-open.sync="isSearchGroupTypeDialogOpen"
            title="Search Group Type"
            :action="getGroupCategoriesAction"
            item-text="name"
            @onSelect="onSelectGroupCategory"
        ></custom-search-dialog>
    </base-dialog>
</template>

<script>
import dateUtility from "@/mixins/date";
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import {
    CREATE_GROUP,
    GET_GROUP_CATEGORIES,
    UPDATE_GROUP,
} from "@/store/modules/group";
import { GET_GG_LEADERS } from "@/store/modules/selection";

const defaultForm = {
    groupLeader: {
        id: null,
        name: null,
    },
    groupCategory: {
        id: null,
        name: null,
    },
    name: null,
    description: null,
};

export default {
    name: "group-form-dialog",

    components: { CustomSearchDialog, BaseInputLabel, BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            error: null,
            isActionStart: false,
            form: Object.assign({}, defaultForm),
            addedGroup: null,
            isSearchGroupLeaderDialogOpen: false,
            isSearchGroupTypeDialogOpen: false,
            getGGLeadersAction: GET_GG_LEADERS,
            getGroupCategoriesAction: GET_GROUP_CATEGORIES,
            groupID: null,
        };
    },

    computed: {
        playlists() {
            return this.$store.state.selection.playlists;
        },

        languages() {
            return this.$store.state.selection.languages;
        },

        dateTimePickerMinDate() {
            return dateUtility.currentDate();
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Group",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Group",
                    }
                );
            }

            return title;
        },
    },

    watch: {
        show(value) {
            this.dialog = value;
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                this.toDefaultFormData(value);
            }
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                this.resetForm();
            }
        },
    },

    methods: {
        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        async onCreate() {
            const payload = {
                ...this.form,
                group_leader_user_id: this.form.groupLeader.id || null,
                group_category_id: this.form.groupCategory.id || null,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                CREATE_GROUP,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "New group was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const payload = {
                groupID: this.groupID,
                ...this.form,
                group_leader_user_id: this.form.groupLeader.id || null,
                group_category_id: this.form.groupCategory.id || null,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_GROUP,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "Group was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        onSelectGroupLeader({ id, prefix, first_name, last_name }) {
            const fullName = `${prefix} ${first_name} ${last_name}`;
            this.form.groupLeader = Object.assign(
                {},
                {
                    id,
                    name: fullName,
                }
            );
        },

        onSelectGroupCategory(groupCategory) {
            this.form.groupCategory = Object.assign(
                {},
                {
                    id: groupCategory.id,
                    name: groupCategory.name,
                }
            );
        },

        toDefaultFormData(payload) {
            const { id, name, description, group_category, group_leader } =
                payload;
            let groupLeader = {
                id: null,
                name: null,
            };
            if (group_leader) {
                const { prefix, first_name, last_name } = group_leader;
                const fullName = `${prefix} ${first_name} ${last_name}`;
                groupLeader = Object.assign(
                    {},
                    {
                        id: group_leader.id,
                        name: fullName,
                    }
                );
            }
            this.groupID = id;
            this.form = Object.assign(
                {},
                {
                    ...this.form,
                    groupLeader,
                    groupCategory: {
                        id: group_category.id,
                        name: group_category.name,
                    },
                    name,
                    description,
                }
            );
        },

        clearCustomSearchInput(property) {
            this.form[property] = Object.assign(
                {},
                {
                    id: null,
                    name: null,
                }
            );
        },
    },
};
</script>
